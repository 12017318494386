import React, { FC } from "react"
import { graphql } from "gatsby"
import marked from "marked"
import Layout from "../components/Layout"
import Img, { ImgProps } from "../components/Img"
import Slideshow from "../components/Slideshow"
import SEO from "../components/SEO"
import { PageProps } from "../types"
import { ProjectProps } from "../types"
import Link from "../components/Link"

interface EmployeeProps {
  subtitle: string,
  title: string,
  people: {
    name: string,
    title: string,
    phoneNumber: string,
    emailAddress: string,
    image: ImgProps
  }[]
}

interface ServiceProps {
  subtitle: string,
  title: string,
  items: {
    title: string,
    text: string
    image: ImgProps
  }[]
}

interface IndexPageTemplateProps {
  intro: {
    title: string,
    projectsButtonText: string,
    bookButtonText: string,
    slideshow: ImgProps[]
  },
  ingress: {
    subtitle: string,
    title: string,
    text: string,
    images: ImgProps[]
  },
  portfolio: {
    subtitle: string,
    title: string,
    projects: ProjectProps[],
    buttonText: string
  },
  projects: {
    nodes: ProjectProps[]
  },
  values: {
    subtitle: string,
    title: string,
    points: {
      title: string,
      description: string
    }[]
  },
  cta: {
    title: string,
    text: string,
    buttonText: string,
    image: ImgProps
  },
  services: ServiceProps,
  employees: EmployeeProps
}

const IndexPageTemplate: FC<IndexPageTemplateProps> = ({
  intro,
  ingress,
  portfolio,
  projects,
  values,
  cta,
  services,
  employees
}) => {
  return (
    <main id="index-page">
      <section
        id="intro"
        className="relative"
      >
        <Slideshow
          autoplay
          slidesPerView={1}
          navigation={true}
          pagination={{
            clickable: true
          }}
        >
          {intro.slideshow.map((slide, index) => (
            <div
              key={index}
              className="h-screen bg-cover bg-center"
              style={{
                backgroundImage: `url(${slide.src.childImageSharp.fluid.src})`,
              }}
            />
          ))}
        </Slideshow>

        <div
          className="p-10 absolute z-10 max-w-4xl w-full flex flex-col sm:px-6"
          style={{
            transform: "translate(-50%, -50%)",
            left: "50%",
            top: "50%"
          }}
        >
          <h1
            className="h1 text-white markdown lg:text-8xl md:text-6xl sm:px-2 sm:text-5xl xs:text-4xl"
            dangerouslySetInnerHTML={{
              __html: marked(intro.title)
            }}
          />

          <div className="mt-16 xs:flex">
            <Link
              className="btn text-center mr-4 md:mr-2 md:px-5 xs:mr-0 xs:mb-2"
              to="/prosjekter"
            >
              {intro.projectsButtonText}
            </Link>

            <Link
              className="btn btn-primary text-center ml-4 md:ml-2 md:px-5 xs:ml-0 xs:mb-2"
              to="mailto:kontakt@ymro.no"
            >
              {intro.bookButtonText}
            </Link>
          </div>
        </div>
      </section>

      <section
        id="om-ymro"
        className="page-section"
      >
        <div className="page-section-text-wrapper max-w-lg">
          <div className="subtitle">
            {ingress.subtitle}
          </div>

          <h2 className="h2">
            {ingress.title}
          </h2>
        </div>

        <div className="mx-auto max-w-5xl w-full flex items-center md:flex-col">
          <div
            className="max-w-xs w-full markdown md:max-w-none"
            dangerouslySetInnerHTML={{
              __html: marked(ingress.text)
            }}
          />

          <div className="flex flex-wrap w-full pl-16 lg:pl-8 md:pl-0 md:mt-8">
            {ingress.images.map(({
              src,
              alt
            }, index) => (
              <div
                key={index}
                className="w-1/2 h-56 p-2 lg:h-48"
              >
                <Img
                  className="w-full h-full"
                  src={src}
                  alt={alt}
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section
        id="prosjekter"
        className="page-section px-0 lg:pb-0"
        style={{
          backgroundImage: "linear-gradient(#0D0D11, 40%, transparent 40%)"
        }}
      >
        <div className="page-section-text-wrapper px-4">
          <div className="subtitle">
            {portfolio.subtitle}
          </div>

          <h2 className="h2">
            {portfolio.title}
          </h2>
        </div>

        <div className="mx-auto max-w-7xl w-full text-center px-2">
          <Slideshow
            navigation={true}
            slidesPerView={1}
            breakpoints={{
              800: {
                slidesPerView: 3
              },
              500: {
                slidesPerView: 2
              }
            }}
          >
            {projects.nodes.map(({
              excerpt,
              frontmatter: { title, meta: { image } },
              fields: { slug }
            }, index) => (
              <div
                key={index}
                className="p-2"
              >
                <Img
                  className="w-full h-64"
                  src={image}
                  alt={title}
                />

                <h3 className="h3 mt-8 mb-4">
                  {title}
                </h3>

                <div
                  className="mx-auto max-w-xs markdown mb-6"
                  dangerouslySetInnerHTML={{
                    __html: marked(excerpt)
                  }}
                />

                <Link
                  to={slug}
                >
                  Se mer ›
                </Link>
              </div>
            ))}
          </Slideshow>
        </div>

        <div className="mt-12 flex justify-center px-4">
          <Link
            className="btn btn-primary"
            to="/prosjekter"
          >
            {portfolio.buttonText}
          </Link>
        </div>
      </section>

      <section
        id="verdier"
        className="py-32 px-0 lg:hidden"
      >
        <div className="page-section-text-wrapper max-w-xl">
          <div className="subtitle">
            {values.subtitle}
          </div>

          <h2 className="h2">
            {values.title}
          </h2>
        </div>

        <div className="mx-auto max-w-7xl w-full flex px-2">
          {values.points.map(({
            title
          }, index) => (
            <div
              key={index}
              className="w-1/4 p-2"
            >
              <h3 className="h3">
                {title}
              </h3>
            </div>
          ))}
        </div>

        <div className="bg-bg1 mt-5">
          <hr className="bg-bg3 h-px w-full" />

          <div className="mx-auto max-w-7xl w-full flex px-2">
            {values.points.map(({
              description
            }, index) => (
              <div
                key={index}
                className="w-1/4 p-2"
              >
                <div className="bg-bg3 -mt-5 circle circle-medium">
                  <div className="bg-pr0 circle circle-small" />
                </div>

                <div
                  className="max-w-xs markdown mt-4"
                  dangerouslySetInnerHTML={{
                    __html: marked(description)
                  }}
                />
              </div>
            ))}
          </div>

          <div
            className="max-w-6xl mx-auto w-full py-8 px-16 bg-cover bg-center relative"
            style={{
              backgroundImage: `url(${cta.image.src.childImageSharp.fluid.src})`,
              bottom: "-8rem"
            }}
          >
            <h2 className="h2">
              {cta.title}
            </h2>

            <p className="mt-4 mb-6 max-w-xl leading-6">
              {cta.text}
            </p>

            <Link
              className="btn btn-primary inline-block"
              to="mailto:kontakt@ymro.no"
            >
              {cta.buttonText}
            </Link>
          </div>
        </div>
      </section>

      <section
        id="tjenester"
        className="page-section"
      >
        <div className="page-section-text-wrapper max-w-2xl">
          <div className="subtitle">
            {services.subtitle}
          </div>

          <h2 className="h2">
            {services.title}
          </h2>
        </div>

        <div className="mx-auto max-w-6xl w-full flex flex-wrap">
          {services.items.map(({
            title,
            text,
            image: {
              src,
              alt
            }
          }, index) => (
            <div
              key={index}
              className="p-6 w-1/3 lg:p-3 md:w-1/2 sm:w-full"
            >
              <Img
                className="w-12"
                src={src}
                alt={alt}
              />

              <h3 className="h3 mt-6 mb-2">
                {title}
              </h3>

              <div
                className="markdown"
                dangerouslySetInnerHTML={{
                  __html: marked(text)
                }}
              />
            </div>
          ))}
        </div>
      </section>

      <section
        id="ansatte"
        className="page-section px-0"
        style={{
          backgroundImage: "linear-gradient(#0D0D11, 40%, transparent 40%)"
        }}
      >
        <div className="page-section-text-wrapper max-w-lg px-4">
          <div className="subtitle">
            {employees.subtitle}
          </div>

          <h2 className="h2">
            {employees.title}
          </h2>
        </div>

        <div className="mx-auto max-w-6xl w-full flex flex-wrap px-4 lg:px-2 md:max-w-lg">
          {employees.people.map(({
            title,
            name,
            emailAddress,
            phoneNumber,
            image: {
              src,
              alt
            }
          }, index) => (
            <div
              key={index}
              className="w-1/4 px-4 lg:px-2 md:w-1/2 md:mb-4"
            >
              <div className="bg-bg1">
                <Img
                  imgStyle={{ objectPosition: "top" }}
                  className="w-full md:h-64 sm:h-56"
                  src={src}
                  alt={alt}
                />

                <div className="p-5 md:p-3">
                  <div className="subtitle text-xs">
                    {title}
                  </div>

                  <h3 className="h3 text-xl leading-6 mb-2 lg:text-lg md:text-base md:mb-1">
                    {name}
                  </h3>

                  <div className="ml-4 flex flex-col leading-6 font-light md:ml-2">
                    <Link
                      className="text-fg1 md:text-sm md:leading-5"
                      to={`mailto:${emailAddress}`}
                    >
                      — {emailAddress}
                    </Link>

                    <Link
                      className="text-fg1 md:text-sm md:leading-5"
                      to={`tel:${phoneNumber}`}
                    >
                      — {phoneNumber}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </main>
  )
}

interface IndexPageProps {
  data: {
    markdownRemark: {
      frontmatter: IndexPageTemplateProps & PageProps,
    },
    projects: {
      nodes: ProjectProps[]
    }
  }
}

const IndexPage: FC<IndexPageProps> = ({ data }) => {
  const { markdownRemark, projects } = data
  const { frontmatter } = markdownRemark
  return (
    <Layout>
      <SEO
        title={frontmatter.meta.title}
        description={frontmatter.meta.description}
        image={frontmatter.meta.image}
      />

      <IndexPageTemplate
        intro={frontmatter.intro}
        ingress={frontmatter.ingress}
        portfolio={frontmatter.portfolio}
        projects={projects}
        values={frontmatter.values}
        cta={frontmatter.cta}
        services={frontmatter.services}
        employees={frontmatter.employees}
      />
    </Layout>
  )
}

export default IndexPage

export const indexPageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {

        meta {
          title
          description
          image {
            childImageSharp {
              fluid(quality: 100 maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        intro {
          title
          projectsButtonText
          bookButtonText
          slideshow {
            src {
              childImageSharp {
                fluid(quality: 100 maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }

        ingress {
          subtitle
          title
          text
          images {
            src {
              childImageSharp {
                fluid(quality: 100 maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }

        portfolio {
          subtitle
          title
          buttonText
        }

        values {
          subtitle
          title
          points {
            title
            description
          }
        }

        cta {
          title
          text
          buttonText
          image {
            src {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }

        services {
          subtitle
          title
          items {
            title
            text
            image {
              src {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }

        employees {
          subtitle
          title
          people {
            name
            title
            phoneNumber
            emailAddress
            image {
              src {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }

      }
    }

    projects: allMarkdownRemark(
      filter: {
        frontmatter: {
          type: {
            eq: "project"
          },
          showOnIndex: {
            eq: true
          }
        }
      }
    ) {
      nodes {
        excerpt(pruneLength: 155)
        frontmatter {
          title
          meta {
            image {
              childImageSharp {
                fluid(maxWidth: 600 quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          # gallery {
          #   caption
          #   image {
          #     src {
          #       childImageSharp {
          #         fluid(maxWidth: 600 quality: 100) {
          #           ...GatsbyImageSharpFluid
          #         }
          #       }
          #     }
          #     alt
          #   }
          # }
        }
        fields {
          slug
        }
      }
    }

  }
`
